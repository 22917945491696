
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Page extends Vue {
  @Prop({ type: String, default: null }) color!: string | null;
  @Prop({ type: String, default: null }) video!: string | null;
  @Prop({ type: Number, default: null }) overlay!: number | null;
  @Prop({ type: Array, default: null }) images!: Array<string> | null;

  videoLoaded = false
  carouselIdx = 0

  get carouselHeight(): number | string {
    return this.$vuetify.breakpoint.mdAndUp
      ? '100vh'
      : '50vh';
  }

  created() {
    this.$store.commit('background', this.color);
    this.$vuetify.goTo('#app', {
      duration: 0
    });
  }
}
