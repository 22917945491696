const links: Array<{
  key: string,
  icon: string | null,
}> = [
  { key: 'home', icon: 'mdi-account' },
  { key: '1on1', icon: 'mdi-video-account' },
  { key: 'retreats', icon: 'mdi-tent' },
  { key: 'testimonials', icon: 'mdi-comment-check-outline' },
  { key: 'contact', icon: 'mdi-email-outline' },
]
const socialLinks: Array<{
  href: string,
  icon: string,
  name: string,
}> = [
  { href: 'https://www.facebook.com/DolamaMantasJacikas', icon: 'mdi-facebook', name: 'Facebook' },
  { href: 'https://www.instagram.com/dolama_mantas?igsh=dGN5eDBvOHY3bzg5', icon: 'mdi-instagram', name: 'Instagram' },
  { href: 'https://www.youtube.com/@Hermit1yoga', icon: 'mdi-youtube', name: 'Youtube' },
]

const availableLanguages = [
  { text: 'English', value: 'en-US', },
  { text: 'Español (Peruvian)', value: 'es-PE', },
  { text: 'Lithuanian', value: 'lt-LT', },
]

const telegramLink = 'https://telegram.me/DolamaMantas';
const emailAddress = 'https://telegram.me/DolamaMantas';

export {
  links,
  socialLinks,
  telegramLink,
  availableLanguages,
}