
import { Component, Vue } from 'vue-property-decorator';
import Sticky from '@/components/Sticky.vue';
import Page from '@/components/Page.vue'

interface ITestimonial {
  expand: boolean,
  key: string,
  thumbnail: string,
  name: string,
  country: string,
  url: string | null,
}

@Component({
  components: {
    Sticky,
    Page,
  }
})
export default class TestimonialsView extends Vue {

  testimonials: Array<ITestimonial> = [
    { expand: false, key: 'dannycoulombe', thumbnail: '/testimonials/danny-coulombe.jpg', name: 'Danny Coulombe' , country: 'Canada', url: 'https://www.coulombe.dev' },
  ]

  get reversedTestimonials(): Array<ITestimonial> {
    return this.testimonials.reverse();
  }
}
