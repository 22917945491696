import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RetreatsView from '../views/RetreatsView.vue'
import OneOnOneView from '../views/OneOnOneView.vue'
import TestimonialsView from '../views/TestimonialsView.vue'
import ContactView from '../views/ContactView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/1on1', name: '1on1', component: OneOnOneView },
  { path: '/retreats', name: 'retreats', component: RetreatsView },
  { path: '/testimonials', name: 'testimonials', component: TestimonialsView },
  { path: '/contact', name: 'contact', component: ContactView },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
