
import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue'
import Sticky from '@/components/Sticky.vue'

@Component({
  components: {
    Page,
    Sticky,
  }
})
export default class RetreatsView extends Vue {

  pictures: Array<string> = [
    'https://www.ayahuasca-wachuma.com/img/ayahuasca/ayahuasca7.jpg',
  ]

  images: Array<string> = [
    '/images/jungle-house.jpg',
    '/images/dolama-shaman.jpg',
    '/images/san-pedro.jpg',
  ]

  upcomingRetreats = [
    { date: 'March 4-17' }
  ]
}
